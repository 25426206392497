import React, { useEffect, useState } from "react";
import logo from "../assets/img/logo.png";
import { Link } from "react-router-dom";

const ThankYou = () => {
    useEffect(() => {

    });
    return (
        <div className="gracias text-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <img src={logo} className="logo mt-5" alt="" />
                        <h3 className="mt-5">
                            ¡Muchas <span>gracias</span> por contactarnos!
                        </h3>
                        <p className="mt-4 mb-4">
                            Recibimos tus datos, en breve <br/>
                            un asesor se comunicará contigo.
                        </p>
                        <Link to={"/"} className="btn-cta">VOLVER</Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThankYou;