import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/gas-estacionario.css";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import bannerFooter from "../assets/img/gas-san-marcos-footer.jpg";
import img1 from "../assets/img/gas-marcos-estacionario-img-01.jpg";
import icono1 from "../assets/img/gas-marcos-icono-indicador.png";
import icono2 from "../assets/img/gas-marcos-icono-rendimiento.png";
import icono3 from "../assets/img/gas-marcos-icono-conveniencia.png";
import icono4 from "../assets/img/gas-marcos-icono-ahorro.png";
import icono5 from "../assets/img/gas-marcos-icono-impacto.png";
import icono6 from "../assets/img/gas-marcos-icono-seguridad.png";
import Header from "./partials/Header";
import Footer from "./partials/Footer";



const GasEstacionario = () => {

    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        console.log(lead);
        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 2) { error = true; msj = "El campo servicio es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";

            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }


    return (
        <div className="gas-estacionario">
            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <p className="mb-3 mt-3"> ¡Directo a tu hogar o negocio! </p>
                            <h1 className="fw-bold fs-50"> Servicio de gas estacionario </h1>
                            <p className="mt-4"> Realiza tu pedido de Gas LP </p>
                            <a href="#contacto" className="btn-cta mt-2 mb-4"> CONTÁCTANOS </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mb-5 mt-5 ff-lato">
                                El servicio de gas estacionario consiste en Gas LP almacenado en su forma líquida, a altas <br />
                                presiones, en un tanque especial de metal. Para solicitar gas a domicilio, es necesario contar con <br />
                                uno de estos tanques, los cuales vienen en una amplia gama de tamaños.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-18">Conoce los beneficios que tiene el servicio gas estacionario para tu hogar o negocio</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon align-items-center">
                                <img src={icono1} className="w-25" />
                                <div class="fs-15 ms-4 ">
                                    <p class="fw-bold">Indicador de nivel</p>
                                    <p class="mb-0 me-3">
                                        Los tanques estacionarios cuentan con un indicador que permite conocer la cantidad de gas restante,
                                        lo que hace fácil saber cuándo es momento de pedir gas a domicilio.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono2} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Rendimiento</p>
                                    <p class="mb-0 me-3">
                                        Normalmente, los tanques de gas tienen una mayor capacidad que los cilindros,
                                        lo que se traduce en un mayor rendimiento.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono3} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Conveniencia</p>
                                    <p class="mb-0 me-3">
                                        El servicio de gas a domicilio evita que los usuarios se tengan que trasladar con sus cilindros
                                        hasta una estación de gas LP para realizar una recarga, por lo que se vuelve algo más práctico y cómodo.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono4} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Ahorro de energía</p>
                                    <p class="mb-0 me-3">
                                        El gas estacionario ofrece un ahorro significativo de energía en comparación con otros combustibles convencionales.
                                        Gracias a su eficiencia y capacidad calorífica, es una excelente opción para negocios y hogares.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono5} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Impacto en el medio ambiente</p>
                                    <p class="mb-0 me-3">
                                        Usar gas estacionario tiene un impacto positivo en el medio ambiente, debido a que el gas LP emite menos contaminantes durante su
                                        combustión, por lo que resulta en una opción más ecológica.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="d-flex icon me-3 align-items-center">
                                <img src={icono6} className="w-25" />
                                <div class="fs-15 ms-4">
                                    <p class="fw-bold">Seguridad</p>
                                    <p class="mb-0 me-3">
                                        Estamos comprometidos con el cumplimiento de altos estándares y protocolos en el manejo del gas LP, con el fin de mantener
                                        un servicio de gas a domicilio completamente seguro.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative text-center mt-5">
                        <div className="btn-box bg-dark-grey-02 position-absolute translate-middle start-50">
                            <a href="#form" className="btn-cta">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2 className="fw-bold mb-4 ff-lato fs-18"> Datos sobre el servicio de gas estacionario </h2>
                            <p className="mb-5 text-center">
                                <li> Los medidores de los tanques de gas marcan el porcentaje de llenado, no la cantidad de litros que hay. </li>
                                <li> Por seguridad, los tanques de Gas LP no deben llenarse más del 90%. </li>
                                <li> Los tanques estacionarios tienen una vida útil de 10 años. </li>
                                <li> Es recomendable solicitar el servicio de gas a domicilio al momento de que el medidor del tanque de gas baje del 10%. </li>
                                <li> Para conocer la cantidad en litros que el medidor marca en porcentaje, éste debe multiplicarse por la capacidad total del tanque. </li>
                                <li> Conocer la capacidad total del tanque de gas que se posee es de gran importancia. </li>
                                <li> Antes de llenar el tanque, es importante identificar el porcentaje de carga inicial, así como el final después del llenado. </li>
                            </p>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-12">
                            <img src={img1} className="w-100" />
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC11 */}
            <div className="sec11 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="ls-25 mt-5 fw-bold fs-18 text-uppercase mb-5">Preguntas frecuentes sobre nuestro servicio.</h3>
                            <div className="accordion accordion-flush faqs">
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-2" aria-expanded="false" aria-controls="faqs-collapse-2">
                                            ¿Qué es el servicio de gas estacionario?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-2" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Es el servicio de <strong> gas a domicilio </strong> por medio del cual se suministra gas LP a un tanque estacionario en un hogar
                                                o negocio.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-3" aria-expanded="false" aria-controls="faqs-collapse-3">
                                            ¿Cómo es el proceso para solicitar el servicio de gas estacionario?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-3" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                El pedido puede realizarse por llamada, WhatsApp o app para programar el servicio. Las unidades acuden a los
                                                domicilios, son monitoreadas hasta surtir el servicio y se entrega un ticket personalizado.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-4" aria-expanded="false" aria-controls="faqs-collapse-4">
                                            ¿Qué hacer en caso de una fuga de gas?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-4" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                En caso de una fuga de gas, se debe cerrar inmediatamente la llave del tanque, ventilar el lugar, evitar contacto
                                                con corrientes eléctricas u otras fuentes de ignición, y una vez fuera del lugar donde ha ocurrido la fuga,
                                                llamar a los servicios de emergencia.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-5" aria-expanded="false" aria-controls="faqs-collapse-5">
                                            ¿Cómo me puedo contactar para solicitar el servicio?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-5" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Para solicitar el servicio de <strong> gas a domicilio </strong> , solamente es necesario llamar al número: (33) 3151 0381
                                                o bien llenar el formulario que se encuentra al final de esta página. <br />
                                                Uno de nuestros asesores le responderá para realizar la contratación.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-6" aria-expanded="false" aria-controls="faqs-collapse-6">
                                            ¿Cómo puedo saber cuál es el tamaño adecuado del tanque de gas para mis necesidades?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-6" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Lo más común para casa habitación son tanques de 120, 160 y 300 litros.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-7" aria-expanded="false" aria-controls="faqs-collapse-7">
                                            Cuando hago un pedido, ¿cuánto tiempo tomaría para que el servicio de gas estacionario sea entregado en mi hogar?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-7" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                El tiempo estimado es durante el transcurso del día y se programa con base en las rutas de las unidades.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC12 */}
            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center desktop-item">Escríbenos, estamos para atenderte</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="fw-bold mb-3 ff-lato ms-4">Obtén un suministro constante de Gas LP para tu hogar o negocio.</h2>
                            <img src={bannerFooter} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center mobile-item mt-4">Escríbenos, estamos para atenderte</p>
                            <form className="bg-dark-grey" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />
                                <input type="text" name="servicio" placeholder="Servicio:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.servicio || ""} required />
                                <input type="tel" name="telefono" placeholder="Télefono" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta fw-bold">¡CONTÁCTANOS!</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default GasEstacionario;