import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/sucursales.css";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import bannerFooter from "../assets/img/gas-san-marcos-footer.jpg";
import img1 from "../assets/img/gas-marcos-estacionario-img-01.jpg";
import img2 from "../assets/img/gas-sanmarcos-sucursales-img-01.jpg";
import img3 from "../assets/img/gas-sanmarcos-sucursales-img-02.jpg";
import icono1 from "../assets/img/gas-sanmarcos-sucursales-icono-01.png";
import icono2 from "../assets/img/gas-sanmarcos-sucursales-icono-02.png";
import icono3 from "../assets/img/gas-sanmarcos-sucursales-icono-03.png";
import icono4 from "../assets/img/gas-sanmarcos-sucursales-icono-04.png";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Link } from "react-router-dom";



const Sucursales = () => {

    const [lead, setLead] = useState({});
    const navigate = useNavigate();

    useEffect(() => {

    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        console.log(lead);
        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 2) { error = true; msj = "El campo servicio es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";

            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }


    return (
        <div className="sucursales">
            <Header />

            {/* SEC1 */}
            <div className="sec1 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center text-white">
                            <h1 className="fw-bold fs-50"> Sucursales </h1>
                            <a href="#contacto" className="btn-cta mt-5 mb-5"> REALIZA TU PEDIDO </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC2 */}
            <div className="sec2 d-pad">
                <div className="container" id="sucursales-sec">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mt-4 fw-bold ff-lato fs-18">
                                Te mostramos el mapa con las ubicaciones de nuestras estaciones de carburación.<br />
                                Visita la sucursal más cercana a ti.
                            </p>
                            <p className="ff-lato fw-bold fs-26 mb-5"> ¡Te esperamos! </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <iframe className="w-100"
                                src="https://www.google.com/maps/d/u/2/embed?mid=1G1EUFuw9IU8hacuyuF9UYuUHwA0TzEc&ehbc=2E312F"
                                height="600"></iframe>
                        </div>
                    </div>
                    <div className="row" role="tablist">
                        <div className="col-md-3"></div>
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5 active" id="sucursal-tab-1" data-bs-toggle="tab"
                                data-bs-target="#sucursal-1" type="button" role="tab" aria-controls="sucursal-1"
                                aria-selected="true">
                                ZACATECAS
                            </button>
                        </div>
                        <div className="col-md-3 text-center">
                            <button className="btn mt-5" id="sucursal-tab-2" data-bs-toggle="tab"
                                data-bs-target="#sucursal-2" type="button" role="tab" aria-controls="sucursal-2"
                                aria-selected="false">
                                AGUASCALIENTES
                            </button>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="sucursal-1" role="tabpanel" aria-labelledby="sucursal-tab-1"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3 d-flex">
                                    <div className="mt-4" >
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Sauceda </p>
                                            <p> Carr. Sauceda de la Borda S/N, Guadalupe, Zac. </p>
                                            <p>
                                                LUNES A DOMINGO <br />
                                                7:00 A.M. - 7:00 P.M.
                                            </p>
                                            <p className="fw-bold"> +525558707969 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 d-flex">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Fresnillo </p>
                                            <p> Carretera # 45 Fllo-Dgo km 62 +700 Fresnillo, Zac. </p>
                                            <p>
                                                LUNES A DOMINGO <br />
                                                7:00 A.M. - 10:00 P.M.
                                            </p>
                                            <p className="fw-bold"> +524922875000 </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="sucursal-2" role="tabpanel" aria-labelledby="sucursal-tab-2"
                            tabindex="0">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="mt-4" >
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Mahatma Gandhi </p>
                                            <p>
                                                Av Mahatma Gandhi S/N, <br />
                                                Desarrollo Especial Centro <br />
                                                de Abastos, #20280 <br />
                                                Aguascalientes, Ags.
                                            </p>
                                            <p>
                                                LUNES A DOMINGO <br />
                                                7:00 A.M. - 7:00 P.M.
                                            </p>
                                            <p className="fw-bold"> +524499764990 </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Norias </p>
                                            <p>
                                                Carretera Norias de Ojo <br />
                                                Caliente, esquina con <br />
                                                Granizo, Ags.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Las Hadas </p>
                                            <p> Heroes de Nacozari #2908, <br /> Fracc. Las Hadas, Ags.  </p>
                                            <p>
                                                LUNES A DOMINGO <br />
                                                7:00 A.M. - 10:00 P.M.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="mt-4">
                                        <div className="card card-body shadow">
                                            <p className="fs-25 fw-bold"> Estación Belisario </p>
                                            <p> Blvd. Siglo XXI #3230 Fracc. <br /> Villas del Pilar, <br /> Aguascalientes, Ags. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC3 */}
            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row mt-4">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-5 fs-25 text-grey"> Recomendaciones al momento de estar en una estación de carburación. </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono1} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono2} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono3} className="w-25" />
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-4">
                            <div className="icon text-center desktop-item">
                                <img src={icono4} className="w-25" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono1} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> Maneja con precaución y a <br /> baja velocidad </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono2} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> Apaga tu motor antes de <br /> que inicie la carga </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono3} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> No utilices tu celular </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mt-md-5 mt-4">
                            <div className="text-center">
                                <div className="icon text-center mobile-item">
                                    <img src={icono4} className="w-25" />
                                </div>
                                <div className="fs-18">
                                    <p className="fw-bold text-grey"> No fumes </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* SEC4 */}
            <div className="sec4 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <h2 className="fw-bold mb-4 fs-25 ff-lato text-grey text-center"> Conoce nuestros servicios. </h2>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-md-6 mobile-item">
                            <img src={img2} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-0 mt-3"> Estacionario </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio <br /> mediante nuestras unidades especializadas. </p>
                            <Link to={'/gas-estacionario'} className="btn-cta mt-4 mb-md-0 mb-5">MÁS INFORMACIÓN</Link>
                        </div>
                        <div className="col-md-6 desktop-item">
                            <img src={img2} className="w-100" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <img src={img3} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <h2 className="fw-bold fs-25 ff-lato mt-md-0 mt-3"> Cilindros </h2>
                            <p className="mt-3"> Llevamos el gas lp hasta tu hogar o negocio mediante <br /> nuestras unidades especializadas. </p>
                            <Link to={'/cilindros'} className="btn-cta mt-4">MÁS INFORMACIÓN</Link>
                        </div>
                    </div>
                </div>
            </div>

            {/* SEC5 */}
            <div className="sec5 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fw-bold mt-4 mb-4 fs-25 ff-lato text-center desktop-item text-light-grey"> ESCRÍBENOS, ESTAMOS PARA ATENDERTE </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-3"> </div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center mobile-item mt-4">Escríbenos, estamos para atenderte</p>
                            <form className="bg-dark-grey" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />
                                <input type="text" name="servicio" placeholder="Servicio:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.servicio || ""} required />
                                <input type="tel" name="telefono" placeholder="Télefono" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta fw-bold"> ENVIAR </button>
                            </form>
                        </div>
                        <div className="col-md-3"> </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Sucursales;