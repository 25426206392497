import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import omniaService from "../services/omnia";
import "../assets/css/home.css";
import Glide from "@glidejs/glide";
import "@glidejs/glide/dist/css/glide.core.min.css";
import "@glidejs/glide/dist/css/glide.theme.min.css";
import leaf from "../assets/img/gas-san-marcos-icono-emisiones.png";
import money from "../assets/img/gas-san-marcos-icono-fuentes.png";
import nosotros from "../assets/img/nosotros.png";
import estacionario from "../assets/img/gas-san-marcos-servicios-estacionario.jpg";
import cilindros from "../assets/img/gas-san-marcos-servicios-cilindros.jpg";
import bannerFooter from "../assets/img/gas-san-marcos-footer.jpg";
import Header from "./partials/Header";
import Footer from "./partials/Footer";
import { Link } from "react-router-dom";


const Home = () => {

    const [lead, setLead] = useState({});
    const navigate = useNavigate();


    useEffect(() => {
        new Glide('.glide', { type: 'slider', perView: 1 }).mount();
    });

    const onSubmitHandler = (event) => {
        event.preventDefault();

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let error = false;
        let msj = "";

        console.log(lead);
        if (!error && lead.nombre.length <= 2) { error = true; msj = "El campo nombre es requerido"; }
        if (!error && lead.telefono.length <= 7) { error = true; msj = "El campo telefono es requerido"; }
        if (!error && lead.email.length <= 5) { error = true; msj = "El campo email es requerido"; }
        if (!error && lead.servicio.length <= 2) { error = true; msj = "El campo servicio es requerido"; }

        if (!error) {
            let data = lead;
            data.utm_id = params.utm_id ? params.utm_id : "";
            data.utm_campaign = params.utm_campaign ? params.utm_campaign : "";
            data.utm_source = params.utm_source ? params.utm_source : "";
            data.utm_medium = params.utm_medium ? params.utm_medium : "";
            data.utm_content = params.utm_content ? params.utm_content : "";
            data.utm_term = params.utm_term ? params.utm_term : "";

            omniaService.saveLead(data).then(res => {
                navigate('/gracias');
            }).catch(error => {
                console.log(error);
            })

        } else {
            alert(msj);
        }

    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setLead({ ...lead, [name]: value });
    }


    return (
        <div className="home">
            <Header />
            <div className="container-fluid sec1 p-0">
                <div className="glide position-relative">
                    <div className="glide__track" data-glide-el="track">
                        <div className="glide__slides text-white">
                            <div className="glide__slide slide1"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide2"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide3"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide4"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide5"><div className="w-100 h-100 op-grey"></div></div>
                            <div className="glide__slide slide6"><div className="w-100 h-100 op-grey"></div></div>
                        </div>
                    </div>
                    <div className="glide__bullets" data-glide-el="controls[nav]">
                        <button className="glide__bullet" data-glide-dir="=0"></button>
                        <button className="glide__bullet" data-glide-dir="=1"></button>
                        <button className="glide__bullet" data-glide-dir="=2"></button>
                        <button className="glide__bullet" data-glide-dir="=3"></button>
                        <button className="glide__bullet" data-glide-dir="=4"></button>
                        <button className="glide__bullet" data-glide-dir="=5"></button>
                    </div>
                    <div className="glide__arrows" data-glide-el="controls">
                        <button className="glide__arrow glide__arrow--left" data-glide-dir="<">
                            <span className="icon-hero-arrow-left text-white"></span>
                        </button>
                        <button className="glide__arrow glide__arrow--right" data-glide-dir=">">
                            <span className="icon-hero-arrow-right text-white"></span>
                        </button>
                    </div>
                </div>
                <div className="text-center text-white position-absolute top-50 start-50 translate-middle w-75">
                    <p className="mb-2">Servicio de distribución de Gas Lp</p>
                    <h1 className="fw-bold fs-58">Tu mejor opción en Gas LP</h1>
                    <p className="mb-4">Distribución eficiente y segura en Aguascalientes y Zacatecas</p>
                    <a href="#contacto" className="btn-cta">REALIZA TU PEDIDO</a>
                </div>
            </div>

            <div className="sec2 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-4">Conoce más sobre el gas lp</h2>
                            <p className="mb-5 ff-lato">
                                El gas licuado de petróleo, comúnmente conocido como gas lp,
                                es un hidrocarburo compuesto<br /> principalmente por propano y butano.
                                Estos componentes se extraen durante el proceso de<br /> fraccionamiento
                                del petróleo y el gas natural.
                            </p>
                            <p>
                                Algunas de las características más distintivas del gas lp son:
                            </p>
                            <p>
                                <span className="text-blue fw-bold">•</span> Combustión casi completa<br />
                                <span className="text-blue fw-bold">•</span> Incoloro y transparente<br />
                                <span className="text-blue fw-bold">•</span> Baja toxicidad<br />
                                <span className="text-blue fw-bold">•</span> Alto rendimiento<br />
                            </p>
                            <a href="#contacto" className="btn-cta">REALIZA TU PEDIDO</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec3 d-pad bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="fw-bold mb-4 fs-25">VENTAJAS DE USAR GAS LP PARA USO INDUSTRIAL Y DOMÉSTICO</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-flames icon fs-50 text-blue me-3"></span>
                                <p className="mb-0">
                                    Ahorro de tiempo y consumo gracias a su alto poder calorífico.
                                </p>
                            </div>

                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <img src={leaf} className="icon-img me-3" />
                                <p className="mb-0">
                                    Las emisiones de contaminantes regulados son muy bajas.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <span className="icon-tank icon fs-50 text-blue me-3"></span>
                                <p className="mb-0">
                                    Se puede almacenar hasta dos meses de consumo.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6 mt-4">
                            <div className="d-flex align-items-center">
                                <img src={money} className="icon-img me-3" />
                                <p className="mb-0">
                                    Es una fuente de energía económica y versátil.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec4 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-5 text-grey">Nuestros servicios en gas lp</h2>
                            <h2 className="fw-bold mb-3 ff-lato">ESTACIONARIO</h2>
                            <p className="mb-5">
                                Llevamos el <strong> gas lp </strong> hasta tu hogar o negocio mediante
                                nuestras unidades especializadas. <br />
                                Suministro directo a tu tanque estacionario a domicilio.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={estacionario} className="w-100" alt="" />
                                <div className="btn-box bg-white position-absolute translate-middle start-50">
                                    <Link to={'/gas-estacionario'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec5 bg-grey text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold mb-3 ff-lato">CILINDROS</h2>
                            <p className="mb-5">
                                Recarga tus cilindros con <strong> gas lp </strong> en cualquiera
                                de nuestras sucursales, donde personal<br />
                                capacitado se encargará de ayudarte.
                            </p>
                            <div className="image-box position-relative text-center">
                                <img src={cilindros} className="w-100" alt="" />
                                <div className="btn-box bg-grey position-absolute translate-middle start-50">
                                    <Link to={'/cilindros'} className="btn-cta">MÁS INFORMACIÓN</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec7 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="ticket-box bg-grey">
                                <h2 className="fw-bold mb-3 ff-lato text-break">TICKETS PERSONALIZADOS</h2>
                                <p className="mb-5">
                                    Identificación de nuestros clientes a través de RFID.
                                    Siempre obtendrá<br /> un ticket con fecha, hora, litros
                                    descargados, importe total y la unidad<br /> que lo realizó.
                                </p>
                                <a href="#contacto" className="btn-cta">FACTURACIÓN</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec8">
                <div className="container">
                    <div className="row">
                        <div className="schedule-box bg-grey">
                            <h2 className="fw-bold fs-58 mb-5 text-center">HORARIOS</h2>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="ls-25 mb-1 fw-bold">HORARIOS REALES</p>
                                    <p>Sucursales • 24 horas</p>
                                    <p className="ls-25 mb-1 fw-bold">HORARIO DE ATENCIÓN <br /> A CLIENTE</p>
                                    <p>7:00 AM a 7:00 PM • Lunes a Sábado</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="ls-25 mb-1 fw-bold">FORMAS DE PAGO</p>
                                    <div className="d-flex mb-3 align-items-center">
                                        <span className="d-inline-block icon icon-cash fs-31 text-light-blue me-3"></span>
                                        <p className="d-inline-block mb-0">Efectivo</p>
                                    </div>
                                    <div className="d-flex mb-3 align-items-center">
                                        <span className="d-inline-block icon icon-credit-card fs-31 text-light-blue me-3"></span>
                                        <p className="d-inline-block mb-0">
                                            Tarjeta de Crédito y Débito<br />
                                            <small className="fs-12 text-grey">Visa y MasterCard</small>
                                        </p>
                                    </div>
                                    <div className="d-flex mb-3 align-items-center">
                                        <span className="d-inline-block icon icon-voucher fs-31 text-light-blue me-3"></span>
                                        <p className="d-inline-block mb-0">
                                            Prepago<br />
                                            <small className="fs-12 text-grey">Vales</small>
                                        </p>

                                    </div>
                                    <div className="d-flex mb-3 align-items-center">
                                        <span className="d-inline-block icon icon icon-transfer fs-31 text-light-blue me-3"></span>
                                        <p className="d-inline-block mb-0">Transferencia Electrónica</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec9 d-pad text-center">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 mb-3 ls-25">SUCURSALES</h2>
                            <p className="ls-25 mb-5 text-grey">ENCUENTRA TU SUCURSAL MÁS CERCANA.</p>
                            <iframe className="w-100" title="mapa"
                                src="https://www.google.com/maps/d/u/2/embed?mid=1G1EUFuw9IU8hacuyuF9UYuUHwA0TzEc&ehbc=2E312F"
                                height="600"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec10 d-pad bg-grey">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="fw-bold fs-58 ls-25 text-center mb-3">NOSOTROS</h2>
                            <p className="ls-25 text-center text-grey mb-5">
                                SOMOS UNA EMPRESA DEDICADA A LA DISTRIBUCIÓN DE GAS LP CON
                                MÁS DE 60 AÑOS.
                            </p>
                            <div className="us-box">
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>
                                            Comercializamos y suministramos Gas LP para
                                            soluciones industriales y residenciales.
                                        </p>
                                        <p className="mb-5">
                                            Contamos con más de 20 autotanques disponibles
                                            a su servicio los 365 días del año. Además de 4
                                            estaciones de carburación para realizar recargas de automóviles.
                                        </p>
                                        <a href="#contacto" className="btn-cta desktop-item">REALIZA TU PEDIDO</a>
                                    </div>
                                    <div className="col-md-6">
                                        <img src={nosotros} className="w-100 img-nosotros" alt="" />
                                        <a href="#contacto" className="btn-cta mobile-item mt-5">REALIZA TU PEDIDO</a>
                                    </div>
                                </div>
                            </div>
                            <p className="ls-25 mt-5 fw-bold fs-18 text-md-start text-center">NUESTROS VALORES</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-integrity-color icon-circle"></span>
                                <p className="ls-25 text-blue box-title">INTEGRIDAD</p>
                                <p className="hover-text">
                                    Cumplimos nuestra<br /> palabra.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-service-color icon-circle"></span>
                                <p className="ls-25 text-blue box-title">SERVICIO</p>
                                <p className="hover-text">
                                    Nuestro diferenciador.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-excellence-color icon-circle"></span>
                                <p className="ls-25 text-blue box-title">EXCELENCIA</p>
                                <p className="hover-text">
                                    Aunque no perfectos, buscando
                                    siempre la mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-humility-color icon-circle"></span>
                                <p className="ls-25 text-blue box-title">HUMILDAD</p>
                                <p className="hover-text">
                                    Aceptando oportunidades con
                                    el reto de mejora.
                                </p>
                            </div>
                        </div>
                        <div className="col-md">
                            <div className="hoverable-card text-center">
                                <span className="icon-respect-color icon-circle"></span>
                                <p className="ls-25 text-blue box-title">RESPETO</p>
                                <p className="hover-text">
                                    Con dignidad para nuestros clientes,
                                    empleados, familias y comunidades.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec11 d-pad">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h3 className="ls-25 mt-5 fw-bold fs-18 text-uppercase mb-5">Preguntas frecuentes sobre nuestro servicio.</h3>
                            <div className="accordion accordion-flush faqs">
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-2" aria-expanded="false" aria-controls="faqs-collapse-2">
                                            ¿Cómo puedo saber si necesito rellenar mi tanque de gas LP?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-2" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Cuando la flama tiene muy poca intensidad o no sale
                                                absolutamente nada en los cilindros, en tanques estacionarios
                                                monitoreando el indicador de nivel que no llegue a 10% de capacidad
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-3" aria-expanded="false" aria-controls="faqs-collapse-3">
                                            ¿Qué formas de pago aceptan para la compra de gas LP?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-3" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Las formas de pago aceptadas para la compra de <strong> gas Lp </strong> son efectivo,
                                                transferencia electrónica, prepago, así como tarjeta de crédito y débito
                                                (Visa y MasterCard).
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-4" aria-expanded="false" aria-controls="faqs-collapse-4">
                                            ¿Cuál es el horario de entrega?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-4" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Nuestro horario de entrega está diseñado para resultar conveniente
                                                y oportuno, por ello abarcamos sábados y días festivos:<br></br>
                                                <span className="fw-bold">- HORARIOS REALES</span><br />
                                                Sucursales • 24 horas<br />
                                                <span className="fw-bold">- HORARIO DE ATENCIÓN A CLIENTE</span><br />
                                                7:00 AM a 7:00 PM • Lunes a Sábado<br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-5" aria-expanded="false" aria-controls="faqs-collapse-5">
                                            ¿Qué sucede si no estoy en casa cuando llega el camión de entrega?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-5" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Se hace una llamada telefónica al cliente y el servicio
                                                se pospone para otro día u horario. Es necesario que
                                                estés presente cuando se surte el pedido
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-6" aria-expanded="false" aria-controls="faqs-collapse-6">
                                            ¿Cómo puedo contratar el servicio de suministro de gas LP?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-6" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                Contratar nuestro servicio de <strong> gas Lp </strong> es muy sencillo,
                                                solamente necesitas llamarnos o enviarnos un mensaje de
                                                whatsapp al número: (81) 8360 1100 o bien llenar el formulario que
                                                se encuentra al final de esta página, uno de nuestros asesores
                                                le responderá para realizar la contratación.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h4 className="accordion-header">
                                        <button className="accordion-button text-grey fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faqs-collapse-7" aria-expanded="false" aria-controls="faqs-collapse-7">
                                            ¿Cuánto tiempo tarda en llegar mi pedido de gas LP?
                                        </button>
                                    </h4>
                                    <div id="faqs-collapse-7" className="accordion-collapse collapse" data-bs-parent="#faqs">
                                        <div className="accordion-body">
                                            <p>
                                                El tiempo estimado es durante el transcurso
                                                del día y se programa con base en las rutas de las unidades.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="sec12 d-pad bg-grey" id="contacto">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center desktop-item">Escríbenos, estamos para atenderte</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <h2 className="fw-bold mb-3 ff-lato ms-4">Obtén un suministro constante de Gas LP para tu hogar o negocio.</h2>
                            <img src={bannerFooter} className="w-100" />
                        </div>
                        <div className="col-md-6">
                            <p className="fw-bold mb-4 fs-25 ff-lato text-center mobile-item mt-4">Escríbenos, estamos para atenderte</p>
                            <form className="bg-dark-grey h-100" onSubmit={onSubmitHandler}>
                                <input type="text" name="nombre" placeholder="Nombre:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.nombre || ""} required />
                                <input type="email" name="email" placeholder="Email:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.email || ""} required />
                                <input type="text" name="servicio" placeholder="Servicio:" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.servicio || ""} required />
                                <input type="tel" name="telefono" placeholder="Télefono" className="form-control rounded-0 mb-2"
                                    onChange={handleChange} value={lead.telefono || ""} required />
                                <textarea rows={5} placeholder="Mensaje:" name="mensaje" className="form-control rounded-0 mb-4"
                                    onChange={handleChange} value={lead.mensaje || ""} ></textarea>
                                <button type="submit" className="btn-cta">Enviar</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Home;